const GRADE_REQUEST_CODE_NA = 'NA'
export const GRADE_REQUEST_CODES_WITHOUT_NA = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10']
export const GRADE_REQUEST_CODES = [...GRADE_REQUEST_CODES_WITHOUT_NA, GRADE_REQUEST_CODE_NA]
export const INVOICE_OPEN_STATUS = 'O'

// WARNING this list of BU can create an error 400 in BFF if BFF does not have the same list
export const BU_CODES = [
  'EHWA',
  'TI',
  'SFAC',
  'CBNL',
  'CBAC',
  'HERM',
  'SIAC',
  'CSOB',
  'EHSK',
  'EHBG',
  'EHRO',
  'HHUN',
  'EHAE'
]

export const UK_BU_CODES = ['TI', 'EHIE']
export const AMERICA_BU_CODES = ['ACI', 'EHCA']
export const OTHER_BU = 'OTHER'

const MANAGE_SECTION = 'manage'
const NON_PAYMENT_SECTION = 'non-payment'
export const RISK_SERVICES_SECTION = 'risk-services'
export const COVER_ESTIMATES_SECTION = 'cover-estimates'

export const COVER_TAB = 'covers'
export const SMARTCOVER_TAB = 'gradebasedcovers'
export const NDCOVER_TAB = 'nd-covers'
export const GRADE_TAB = 'grades'
export const CLAIMS_TAB = 'claims'
export const OVERDUES_TAB = 'overdues'
export const RESCHEDULINGS_TAB = 'reschedulings'

// TABS
export const COVER_TABS = [COVER_TAB, SMARTCOVER_TAB, NDCOVER_TAB]
export const RISK_SERVICES_TABS = [...COVER_TABS, GRADE_TAB]
export const NON_PAYMENT_TABS = [RESCHEDULINGS_TAB, OVERDUES_TAB, CLAIMS_TAB]
export const MANAGE_TABS = NON_PAYMENT_TABS.concat(RISK_SERVICES_TABS)
export const NO_TAB = []

export const TABS_PER_SECTION = {
  [MANAGE_SECTION]: MANAGE_TABS,
  [NON_PAYMENT_SECTION]: NON_PAYMENT_TABS,
  [RISK_SERVICES_SECTION]: RISK_SERVICES_TABS
}

export const GRADE_MONITORED = 'monitored'
export const GRADE_NON_MONITORED = 'non-monitored'

const ANSWERED = 'ANSWERED'
const REJECTED = 'REJECTED'
const CANCELLED = 'CANCELLED'
const PENDING = 'PENDING'
const TERMINATED = 'TERMINATED'
export const withBrokerAppealCode = 'With'

export const GRADE_REQUEST_STATUSES = [ANSWERED, REJECTED, CANCELLED, PENDING, TERMINATED]

export const GR_GRADE_REQUEST = 'GR'

export const EXISTING_TYPE = 'existing'
export const PENDING_TYPE = 'pending'
export const STRATEGIC_TYPE = 'strategic'
export const NON_STRATEGIC_TYPE = 'nonStrategic'

export const TILE = 'tile'
export const TABLE = 'table'

export const VIEWS = { TILE, TABLE }

export const TCI = 'tci'
export const SURETY = 'surety'
export const MIDTERM = 'mid-term'
export const SURETY_SUPPORT_LINK =
  'https://www.allianz-trade.com/en_global/customer-service-lines.html#TabVerticalNegative11499129389'
export const SURETY_HERM_SUPPORT_LINK = 'https://www.allianz-trade.de/service/fuer-kunden/ktv-dispo-individual-faq.html'
export const SURETY_HERM_SRT_SUPPORT_LINK = 'https://www.allianz-trade.de/service/fuer-kunden/ktv-smart-faq.html'
export const TCI_SUPPORT_LINK = '/support/ticket-list'

export const ONBOARDING_TYPE_SURETY = 'suretyOnboarding'
export const ONBOARDING_TYPE_TCI = 'tciOnboarding'
export const ONBOARDING_TYPE_RISK = 'riskServicesOnboarding'
export const ONBOARDING_TYPE_NON_PAYMENT = 'nonPaymentOnboarding'
export const ONBOARDING_TYPE_POLICY_ADMINISTRATION = 'policyAdministrationOnboarding'

export const COUNTRY_NOT_COVERED = 'countryNotCovered'

export const ND_COVER_DECISION_LABEL_LIST = [ANSWERED, REJECTED, CANCELLED, PENDING, TERMINATED]
export const ND_COVER_STATUS_LIST = ['cover', 'nocover', 'inactive', 'pending']
export const PREDEFINED_PERIODS = [
  'last-connection',
  'this-week',
  'last-week',
  'this-month',
  'last-month',
  'this-year',
  'last-year'
]

export const PREDEFINED_POLICY_PERIODS = ['current-policy-period', 'previous-policy-period']

export const TURNOVER_DECLARATION_TO_DO_STATUS_LIST = ['O', 'F', 'S', 'L', 'N', 'P', 'DO', 'DF', 'DS', 'DL', 'DN', 'DP']

export const PRI_MODEL_SAR = 'SAR'
export const PRI_MODEL_SAL = 'SAL'

// The new user permission added in webApp should be also added in BFF (api/lib/constants.js; const allPermissionsAUserCanHave)
export const BROKER_TYPE = 'INTERMEDIARY'
export const PERMISSION_BROKER_DASHBOARD = 'dashboard'
export const PERMISSION_MONITOR = 'monitor'
export const PERMISSION_TOD_ACCESS = 'tode_system_login'
export const PERMISSION_MIDAS_ACCESS = 'midas_front_access'
export const PERMISSION_BROKERAGE_PAID = 'brokerage_paid'
export const PERMISSION_NEW_BUSINESS_CURRENT_PORTFOLIO = 'new_business_current_portfolio'
export const PERMISSION_NO_TRANSACTIONAL = 'no_transactional'
export const PERMISSION_TA_CONTENT = 'ta_content'
export const PERMISSION_RABOBANK_CONTENT = 'rabobank_content'
export const PERMISSION_CLIENT_CONTENT = 'client_content'
export const PERMISSION_INTERMEDIARY_CONTENT = 'intermediary_content'
export const PERMISSION_EDOC = 'edoc'
export const PERMISSION_EOLIS_ACCESS = 'eolis_access'
export const PERMISSION_EOLIS_LOGIN = 'eolis-login'
export const PERMISSION_EOLIS_MULTINATIONAL_ACCESS = 'eolis_wa_access'
export const PERMISSION_LIBRARY_CLIENT = 'library-client'
export const PERMISSION_EOLIS_WA_LOGIN = 'eolis-wa-login'
export const PERMISSION_EXPORT = 'export-tables'
export const PERMISSION_READ_COMPANY_GRADE = 'read_company_grade'
export const PERMISSION_READ_STRATEGIC = 'read_strategic_cover'
export const PERMISSION_WRITE_STRATEGIC = 'write_strategic_cover'

export const PERMISSION_POLICY_PERFORMANCE = 'ato_front_policyperformance_broker'
export const PERMISSION_COLLECTIONS_ACCESS = 'collection-login'
export const PERMISSION_CREDEMTEL_ACCESS = 'ato_front_credemtel_sso-login'
export const PERMISSION_WRITE_CLAIMS = 'write_claims'
export const PERMISSION_WRITE_OVERDUES = 'write_overdues'
export const PERMISSION_READ_CLAIMS = 'read_claims'
export const PERMISSION_READ_OVERDUES = 'read_overdues'
export const EOLIS_MULTINATIONAL_BU = 'EHWA'
export const PERMISSION_SMARTVIEW_LOGIN = 'myeh_api_smartview_group_login'
export const PERMISSION_SMARTVIEW_WA_LOGIN = 'myeh_api_smartview_worldagency_login'
export const PERMISSION_READ_POLICY_DRAFT = 'ato_front_finalized-policy-drafts'
export const PERMISSION_READ_POLICY_ADMINISTRATION = 'ato_front_policyadmin_read'
export const PERMISSION_WRITE_POLICY_ADMINISTRATION = 'ato_front_policyadmin_write'
export const PERMISSION_POLICY_SELECTOR_SEARCH_WITH_HIERARCHY = 'ato_front_policyselector_searchmultipolicy'
export const PERMISSION_POLICY_SELECTOR_SEARCH_WITHOUT_HIERARCHY = 'ato_front_policyselector_searchmonopolicy'
export const PERMISSION_ATO_FRONT_USER_SURETY_ACCESS = 'ato_front_user_surety_access'
export const PERMISSION_SURETY_READ_ONLY = 'olb_read_only'
export const PERMISSION_SURETY_FOUR_EYES_REQUESTOR = 'olb_requestor_four_eyes'
export const PERMISSION_SURETY_FOUR_EYES_VALIDATOR = 'olb_validator_four_eyes'
export const PERMISSION_SURETY_FOUR_EYES_REQUESTOR_VALIDATOR = 'olb_requestor_validator'
export const PERMISSION_SURETY_SIX_EYES_REQUESTOR = 'olb_requestor_six_eyes'
export const PERMISSION_SURETY_SIX_EYES_INTERMEDIARY_VALIDATOR = 'olb_validator_intermediary_six_eyes'
export const PERMISSION_SURETY_SIX_EYES_FINAL_VALIDATOR = 'olb_validator_final_six_eyes'
export const PERMISSION_BUYER_SYNTHESIS_BASIC = 'ato_front_basic_buyersynthesis'
export const PERMISSION_BUYER_SYNTHESIS_ADVANCED = 'ato_front_advanced_buyersynthesis'
export const PERMISSION_ATO_FRONT_USER_MIDTERM_ACCESS = 'ato_front_user_mid-term_reader'

export const HIDDEN_COMPANY_STATUSES = ['P', 'UNKNOWN']
export const SHOWED_COMPANY_COUNTRIES_OLDSEARCH_STATES = ['AU', 'CH', 'TH', 'US', 'CA']
// risk permissions
export const PERMISSION_READ_MONITORED_GRADE = 'read_gr'
export const PERMISSION_READ_ND_COVER = 'read_unnamedcover'
export const PERMISSION_READ_CLR = 'read_clr'

// temporary removed 'BR', 'CN', 'JP', 'KP', 'RU', 'TH', 'UA', 'VE'
export const SHOWED_COMPANY_COUNTRIES_STATES = ['AU', 'CA', 'CH', 'MX', 'US']
export const COUNTRIES_ISO_STATES = ['CH', 'MX']

export const BUCODES = {
  GERMANY: 'HERM',
  USA: 'ACI',
  CANADA: 'EHCA',
  UK: 'TI',
  FRANCE: 'SFAC',
  DENMARK: 'EHDK',
  FINLAND: 'EHFI',
  NORWAY: 'EHNO',
  SWEDEN: 'EHSE',
  NETHERLANDS: 'CBNL',
  BELGIUM: 'CBAC',
  SWITZERLAND: 'HSWI',
  POLAND: 'HPOL'
}

export const PAYMENT_STATUS_REFERENCE = {
  C: 'cleared',
  O: 'open'
}

export const LIBRARIES_BUCODE_MAPPING = {
  SFAC: 'FR',
  EUBE: 'FR',
  TI: 'GB',
  EHIE: 'GB',
  SIAC: 'IT',
  HERM: 'DE',
  CBAC: 'BE',
  CBNL: 'NL',
  EHWA: 'WA',
  EBHK: 'HK',
  EBSG: 'SG',
  EHJP: 'JP',
  EHTW: 'TW',
  ACI: 'US',
  EHCA: 'CA',
  HPOL: 'PL',
  EHGR: 'GR',
  EHTR: 'TR',
  CSOB: 'CZ',
  EHSK: 'SK',
  EHBG: 'BG',
  EHRO: 'RO',
  HHUN: 'HU',
  EHAE: 'AE'
}

export const SUFFIX_URL_TIED_AGENT = '/tied-agent'
export const SUFFIX_URL_RABOBANK = '/rabobank'

export const CLAIM_FORM_STATUSES = {
  VALIDATED: 'validated',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

export const MAX_CONTENT_HEIGHT = 135
export const INACTIVE_STATUS = 'inactive'

export const FRENCH_BUYER_COUNTRY_CODE = ['FR', 'AQ', 'GF', 'GP', 'MQ', 'NC', 'PF', 'PM', 'RE', 'TF', 'WF', 'YT', 'AD']

export const UTF_BOM = '\uFEFF'

export const heightOfPolicyInPolicySelector = 31

export const ALLIANZ_TRADE_RESPONSIVE_MENU_THRESHOLD = 1040

export const BROKER_COMMISSIONS_SMALL_BREAKPOINT = 810
export const BROKER_COMMISSIONS_PAGE_SIZE = 10
export const BROKER_COMMISIONS_STATUS_WITH_FILE = '6'

export const IDLE_MODAL_TIMEOUT = 1000 * 60 * 60
export const EXPIRED_SESSION_MODAL_COUNTER = 60

export const TURNOVER_DECLARATIONS_TAB = 'turnoverDeclarations'
export const PREMIUM_AND_FEES_TAB = 'premiumAndFees'
export const MIXED_EXPOSURE_PACKAGE_TAB = 'mixedExposurePackage'

export const INVOICE_DETAILS_PAGE_SIZE = 15

export const COVER_JOBS = 'cover-jobs'
export const COVER_REQUEST_JOBS = 'cover-request-jobs'

export const PACKAGE_FEES_CLR = 'clr'
export const PACKAGE_FEES_SMARTCOVER = 'smartcover'
export const PACKAGE_FEES_MONITORED = 'monitored-grade'
export const PACKAGE_FEES_GRADE = 'grade-information'
export const EXPOSURE_THRESHOLD = 'exposure-threshold'

export const BY_NAME = 'byName'
export const BY_IDENTIFIER = 'byIdentifier'

export const EHID = 'ehid'
export const OTHER_IDENTIFIER = 'other'

export const ATO_MEDIUM_BREAKPOINT = 704

export const TOD_DECLARATION_TYPE_CODES = { COUNTRY: 'COUNTRY', OTHER: 'OTHER', BLENDED: 'BLENDED', DOMEXP: 'DOMEXP' }
export const TOD_DECLARATION_ROW_TYPES = { DOM: 'DOM', EXP: 'EXP', INS: 'INS', NINS: 'NINS', TOT: 'TOT' }

export const MONTHLY_EXCHANGE_RATE_SUPPORTED_CURRENCIES = [
  'AED',
  'ARS',
  'AUD',
  'BAM',
  'BGN',
  'BHD',
  'BND',
  'BOB',
  'BRL',
  'CAD',
  'CHF',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'GHS',
  'GTQ',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JOD',
  'JPY',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'MAD',
  'MUR',
  'MXN',
  'MYR',
  'NAD',
  'NOK',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'ROL',
  'RON',
  'RSD',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TND',
  'TRY',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'VES',
  'VND',
  'XAF',
  'XOF',
  'ZAR'
]

export const BYPASS_MAINTENANCE_COOKIE_NAME = 'Bypass-Maintenance-Page'
export const INVALID_SUBMISSION_DATE = '1900-01-01T00:00:00.000000Z'
export const GTU_ERROR_COOKIE_KEY = 'kndctr_CEE1F70B5936D5B30A495EA3_AdobeOrg_identity'

export const JERSEY_GUERNSEY_ISLANDS_CODE = 'JG'

export const ICONS_MAPPING = {
  danger: 'product-danger',
  hourglass: 'product-hourglass',
  'hourglass-o': 'product-hourglass',
  cross: 'product-cross',
  check: 'product-check'
}

export const TOP_UPCOMING_RENEWALS_ROWS = 5

export const SIDEPANEL_TOP_SHIFT_SMALL = '6.3rem'
export const SIDEPANEL_TOP_SHIFT_LARGE = '4.05rem'

// Precheck type codes
const PRECHECK_TYPE = 'PreCheck'
const PRECHECK_CAP_TYPE = 'PreCheckCap'
const PRECHECK_CAP_PLUS_TYPE = 'PreCheckCapPlus'
export const PRECHECK_TYPES = [PRECHECK_TYPE, PRECHECK_CAP_TYPE, PRECHECK_CAP_PLUS_TYPE]

export const SMALL_WIDTH = 775

export const FIVE_MINUTES = 5 * (60 * 1000)

export const DISCLAIMER_HIDDEN = 'hidden'
export const DISCLAIMER_COVER = 'cover'
export const DISCLAIMER_OVERDUE = 'overdue'
export const DISCLAIMER_RESCHEDULING = 'rescheduling'
